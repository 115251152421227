.tracker-chart svg {
  overflow: visible !important;
}

.full {
   opacity: 1;
}

.dim {
  opacity: 0.1;
}