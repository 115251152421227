.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.movable-item,
.destination-label {
  border-radius: 0.3em;
  background-color: #fafaff;
  color: #4c4c4c;
  min-height: 2em;
  min-width: 8em;
  margin: 0.5em auto;
  padding: 0 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: #ccc 1px solid;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.movable-item {
  float: left;
  margin: 0.5em 1em;
  padding: 0 0.5em;
}

.movable-item:hover {
  background-color: rgba(0, 149, 253, 0.08);
  color: #d94936;
  border-color: #d94936;
  cursor: pointer;
}

.mapper-container-border {
  border-radius: 0.5em;
  border: #ccc 1px solid;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.mapper-row-container {
  min-width: 30em;
  resize: both;
  overflow: auto;
  padding-bottom: 1em;
  margin: 0 1em;
}

.mapper-row {
  display: flex;
  border: #ccc 1px solid;
  margin: 0 1em 0.5em 1em;
  padding: 0 0.5em;
  border-radius: 0.5em;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.mapper-row .mapper-col {
  flex: 50%;
  justify-items: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.mapper-col .movable-item {
  margin: 0;
}

.items-pool {
  height: max-content;
  min-height: 30em;
  min-width: 15em;
  width: 30em;
  resize: both;
  overflow: auto;
}

.items-pool-header {
  border-bottom: #ccc 2px solid;
  width: 100%;
  height: 4em;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}

.controls-pane {
  height: max-content;
  min-height: 50em;
  min-width: 30em;
  margin: 1em;
  border-radius: 0.5em;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.controls-pane button {
  margin-bottom: 5px;
  width: 100%;
}

.tabbed {
  width: 100%;
  margin-top: 0.2em;
  border-bottom: 4px solid #0095fd;
  overflow: hidden;
  transition: border 250ms ease;
}
.tabbed ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  float: right;
  padding-left: 0px;
  list-style-type: none;
}
.tabbed ul * {
  margin: 0px;
  padding: 0px;
}
.tabbed ul li {
  display: block;
  float: left;
  padding: 1em;
  background-color: #fff;
  margin-right: 0px;
  z-index: 2;
  position: relative;
  cursor: pointer;
  color: #777;
  border-radius: 1.5em 6px 0 0;
  transition: all 250ms ease;
}

.tabbed ul li:before,
.tabbed ul li:after {
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #fff;
  transition: all 250ms ease;
}

.tabbed ul li:hover,
.tabbed ul li:hover:before,
.tabbed ul li:hover:after {
  background-color: #ccc;
  color: #444;
}
.tabbed ul li.active {
  z-index: 3;
}
.tabbed ul li.active,
.tabbed ul li.active:before,
.tabbed ul li.active:after {
  background-color: #0095fd;
  color: #fff;
}
