.full-size {
    height: 100%;
    width: 100%;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.flex {
    display: flex;
}

.flex-direction-column {
    flex-direction: column;
}

.flex-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}