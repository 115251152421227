.lsr-action {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 16px;
}

.lsr-description {
  margin-bottom: 16px;
}

.lsr-listing-viewer {
  padding: 0 8px;
}

.lsr-listing-title {
  margin-bottom: 12px;
}

.lsr-listing {
  padding-inline-start: 40px;
  list-style-type: disc;
  line-height: 1.4em;
}

.lsr-nested-listing {
  padding-inline-start: 40px;
  list-style-type: circle;
}