  html, body {
    height:100%;
  }

  .row {
    height:100%;
    display: flex;
  }

  .column-left {
    flex:25%;
    height:100%;
  }

  .column-right {
    flex: 75%;
    height: 100%;
    min-width: 800px;
  }

  .left-panel {
    margin-top:10px;
    margin-left:10px;
    padding: 1em;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 400px;
    min-width: 300px;
  }

  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
    box-sizing: border-box;
  }

  .react-grid-item.cssTransforms {
    transition-property: transform;
  }

  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  .react-grid-item.react-grid-placeholder {
    background:#007bff;
    opacity: 0.2;
    transition-duration: 50ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .resizable-layout-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
    opacity: 0.3;
  }

  .resizable-layout-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 15px;
    height: 15px;
    border-right: 4px solid #333;
    border-bottom: 4px solid #333;
  }

  .resizable-layout-item> .react-resizable-handle:hover {
    opacity: 0.8;
  }

  .top-indent {
    margin-top: 1em;
  }  

  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    opacity: 0.3;
    cursor: pointer;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 10px;
    content: ' ';
    height: 20px;
    width: 4px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .grid-widget-item {
    display: flex;
  }

  .configurator-layout-item {
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.2);
    padding: 1em;
  }

  .p-1em {
    padding: 1em;
  }

  .grid-layout-combobox{
    margin-right: 1em;
    flex: 70%;
    min-width: 150px;
  }

  .widget-names {
    color: #666;
  }

  .widget-names-header {
    text-transform: uppercase;
    color: #007bff;
    font-weight: 500;
  }

  .font-weight-bold {
    font-weight: 500;
  }

  .grid-widget-item .MuiCardContent-root {
    padding: 0 !important ;
  }

  @media only screen and (max-width: 900px) {
    .grid-widget-item  {
      flex-direction: column;
    }

    .grid-layout-combobox {
      flex:50%;
    }

    .grid-item-layout-info {
      flex:50%;
    }
  }

  .grid-layout-button {
    margin: 0 .5em .5em 0 !important;
  }